import "./css_index/main.css";
import "./css_index/style.css";
import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Link, useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';


import axios from "axios";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';

import FormControl from '@mui/material/FormControl';

import {
  CardMedia,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  InputLabel,
  Input,
  InputAdornment,
} from "@mui/material";

import protocolo from "../files/protocolos.png";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";

const columns = [
  { id: 'termino', label: 'Termino', minWidth: 170 },
  { id: 'descripcion', label: 'Descripción', minWidth: 170 },

];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const pages = ["Inicio"];
const settings = ["Salir"];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Help() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openGlosario, setOpenGlosario] = React.useState(false);
  const handleOpenGlosario = () => setOpenGlosario(true);
  const handleCloseGlosario = () => setOpenGlosario(false);

  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/login");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [glosario, setDataGlosario] = React.useState([]); 
  const [glosarioCopy, setDataGlosarioCopy] = useState([]);
  const [buscador, setBuscador] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getGlosario = async () => {
    try {
      const response = await axios.get('https://sabiduria.dtedevelop.cl/api/glosario/');
      const data = response;
      console.log(data.data.glosario);
      setDataGlosario(data.data.glosario);
      setDataGlosarioCopy(data.data.glosario);
      
    } catch (error) {
      console.log(error);
    }
  }

  const buscar = async (event) => {
    
    setBuscador(event.target.value);
    const copy = glosarioCopy;

    const termino = copy.filter(
      element => element.termino.toLowerCase().includes(event.target.value.toLowerCase())
      ) //return invisible

      setDataGlosario(termino);
  };

  useEffect(() => {

    getGlosario();

  }, []);

  return (
    <div>
      <AppBar position="fixed" style={{ background: "#022746", zIndex: 1201 }}>
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1, p: 1 }}>
            <img src="/logo2.svg" width={100} alt="logo aiep" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  navigate("/");
                }}
              >
                <Typography textAlign="center">Inicio</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  navigate("/ayuda");
                }}
              >
                <Typography textAlign="center">Ayuda</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
            <img src="/logo2.svg" width={100} alt="logo aiep" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {/* {pages.map((page) => ( */}
            <Button
              // key={page}
              onClick={() => {
                handleCloseNavMenu();
                navigate("/");
              }}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Inicio
            </Button>
            <Button
              // key={page}
              onClick={() => {
                handleCloseNavMenu();
                navigate("/ayuda");
              }}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Ayuda
            </Button>
            {/* ))} */}
          </Box>

          <Box sx={{ flexGrow: 0, p: 1 }}>
            <Button
              key={"login"}
              onClick={goToLogin}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Iniciar Sesión
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <div className="titlecontainer" style={{ marginTop: 120 }}>
        <div className="titlepage text-center">
          <h2>Enlaces</h2>
        </div>
      </div>

      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="https://intranet.aiep.cl/docs/recursos-pr%C3%A1ctica-docente/manual-de-estrategias-y-t%C3%A9cnicas-de-formaci%C3%B3n.pdf?sfvrsn=2"
          >
            <ListItemIcon>
              <BookmarkIcon />
            </ListItemIcon>
            <ListItemText primary="Manual de estrategias y técnicas de formación" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="https://intranet.aiep.cl/docs/recursos-pr%C3%A1ctica-docente/lineamientos-de-evaluaci%C3%B3n-para-y-de-los-aprendizajes-aiep.pdf?sfvrsn=2"
          >
            <ListItemIcon>
              <BookmarkIcon />
            </ListItemIcon>
            <ListItemText primary="Lineamientos de evaluación para y de los aprendizajes." />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="https://transparencia.aiep.cl/index.php/doc/modelo-educativo/"
          >
            <ListItemIcon>
              <BookmarkIcon />
            </ListItemIcon>
            <ListItemText primary="Modelo educativo AIEP" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleOpenGlosario}>
            <ListItemIcon>
              <BookmarkIcon />
            </ListItemIcon>
            <ListItemText primary="Glosario" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleOpen}>
            <ListItemIcon>
              <BookmarkIcon />
            </ListItemIcon>
            <ListItemText primary="Protocolo de uso del asistente instruccional" />
          </ListItemButton>
        </ListItem>
      </List>
      <div className="titlecontainer">
        <div className="titlepage text-center">
          <h2>Videos</h2>
        </div>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CardMedia
            component="iframe"
            sx={{ marginTop: 3, border: "none" }}
            height="350"
            image="https://player.vimeo.com/video/880971584?h=c0bd8c4af5&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
            title="Introducción Asistente Instruccional"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardMedia
            component="iframe"
            sx={{ marginTop: 3, border: "none" }}
            height="350"
            image="https://player.vimeo.com/video/910807160?h=72dae5ecce&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            title="Orientaciones Metodológicas Asistente Instruccional"
          />
        </Grid>
      </Grid>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ alignSelf: "center", marginTop: "10px" }}>
          <img src={protocolo} width={600} alt="logo aiep" />
        </Box>
      </Dialog>

      <Dialog
        fullScreen
        open={openGlosario}
        onClose={handleCloseGlosario}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseGlosario}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ alignSelf: "center", marginTop: "10px" }}>
          
        <div className="titlecontainer">
          <div className="titlepage text-center">
            <h2>Glosario</h2>
          </div>
        </div>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <Grid container xs={12} justify="space-between" columns={12}>
                <Grid xs={8}></Grid>
                <Grid align="right" xs={4} mdOffset="auto">

                <FormControl sx={{ m: 1, width: '25ch', marginTop: '-3px'}} variant="standard">
                  <InputLabel htmlFor="Buscar">Buscar</InputLabel>
                  <Input
                    id="Buscar"
                    value={buscador} 
                    onChange={buscar} 
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  </FormControl>
                </Grid>
              </Grid>

              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {glosario.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            <TableCell>
                              {
                                row.termino
                              }
                            </TableCell>
                            <TableCell>
                              {
                                row.descripcion
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={glosario.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>

        </Box>
      </Dialog>

      <div id="footer" style={{ marginTop: 20 }}>
        <div className="copyright footer-col-left">
          <p>
            © 2023.{" "}
            <a href="https://www.aiep.cl/">VICERRECTORÍA ACADÉMICA AIEP</a>
          </p>
        </div>
        <div className="footer-col-right">
          <ul className="social_icon text_align_center">
            <li>
              {" "}
              <a href="https://web.facebook.com/admision.aiep" target="_blank">
                <i className="fa fa-facebook-f"></i>
              </a>
            </li>
            <li>
              {" "}
              <a href="https://twitter.com/AIEP" target="_blank">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://cl.linkedin.com/school/instituto-profesional-aiep/"
                target="_blank"
              >
                <i className="fa fa-linkedin-square" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://www.instagram.com/admision_aiep/"
                target="_blank"
              >
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              {" "}
              <a href="https://www.youtube.com/@AIEPtv" target="_blank">
                <i className="fa fa-youtube-play" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Help;
