import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Barra } from "../components/Barra";
import { DataGrid, esES } from "@mui/x-data-grid";
import { AuthContext } from "../context/AuthContext";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from '@mui/icons-material/Description';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import axios from "axios";
import moment from "moment";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import {
  AlignmentType,
  Document,
  Header,
  HeadingLevel,
  Packer,
  Paragrap,
  Paragraph,
  TextRun,
} from "docx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const History = () => {
  const { user } = React.useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [rowSelected, setRowSelected] = useState([]);

  const [open, setOpen] = React.useState(false);

  const columns = [
    { field: "id", headerName: "ID", minWidth: 50, flex: 1 },
    { field: "asignatura", headerName: "Módulo", minWidth: 150, flex: 1 },
    { field: "unidad", headerName: "Unidad", minWidth: 120, flex: 1 },
    { field: "aprendizaje", headerName: "Aprendizaje", minWidth: 250, flex: 1 },
    {
      field: "created_at",
      headerName: "Fecha",
      minWidth: 170,
      flex: 1,
      valueFormatter: (params) =>
        moment(params?.value).format("DD-MM-YYYY hh:mm A"),
    },
    { field: "prompt_title", headerName: "Pregunta", minWidth: 250, flex: 1 },
    {
      field: "action",
      headerName: "Respuesta",
      sortable: false,
      renderCell: ({ row }) => (
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setRowSelected(row);
            handleClickOpen();
          }}
        >
          Ver
        </Button>
      ),
      minWidth: 100,
      flex: 1,
    },
  ];

  const getHistory = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://sabiduria.dtedevelop.cl/api/bitacora/${user.user}`,
      headers: {
        Authorization: "Bearer " + user.authorization.token,
      },
    };

    const response = await axios.request(config);

    const responseHistory = response.data;
    const bitacora = responseHistory.bitacora;
    let finalHistory = [];
    for await (const result of bitacora) {
      const info = await getAprendizajes(result.aprendizaje_id);
      finalHistory.push({
        id: result.id,
        prompt_title: result.prompt_title,
        respuesta: result.respuesta,
        created_at: result.created_at,
        asignatura: info.asignatura,
        unidad: info.unidad,
        aprendizaje: info.aprendizaje,
      });
    }

    // console.log(finalHistory);

    setRows(finalHistory);
  };

  const getAprendizajes = async (id) => {
    let data = JSON.stringify({
      aprendizaje_id: id,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://sabiduria.dtedevelop.cl/api/infoaprendizaje",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.authorization.token,
      },
      data: data,
    };

    const response = await axios.request(config);

    const responseInfo = response.data;

    return {
      asignatura: responseInfo.asignatura.namedescripcion,
      unidad: responseInfo.unidad.name,
      aprendizaje: responseInfo.aprendizaje.name,
    };
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveToDoc = (text) => {
    let doc = new Document({
      sections: [
        {
          properties: {},
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  text: "Asistente Instruccional | AIEP",
                  heading: HeadingLevel.TITLE,
                }),
              ],
            }),
          },
          children: [
            new Paragraph(text),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "respuesta.docx");
    });
  };


  const saveToPdf = (text) => {
    const doc = new jsPDF();
    doc.text(text, 10, 10, {maxWidth: 185, align: "justify"});
    doc.save("respuesta.pdf");
  };

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <Box>
      <Barra />
      <Box
        sx={{
          bgcolor: "background.paper",
          //   pt: 10,
          //   pb: 2,
          p: 10,
        }}
      >
        <Typography
          component="h3"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Historial
        </Typography>

        <DataGrid
          getRowHeight={() => "auto"}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          density="compact"
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      </Box>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullScreen
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <AppBar sx={{ position: "relative", background: "#022746" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Respuesta
            </Typography>
            <IconButton
              color="inherit"
              onClick={() => {
                navigator.clipboard.writeText(rowSelected.respuesta);
              }}
              aria-label="close"
            >
              <ContentCopyIcon />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => {
                saveToDoc(rowSelected.respuesta);
              }}
              aria-label="close"
            >
              <DescriptionIcon />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => {
                saveToPdf(rowSelected.respuesta);
              }}
              aria-label="close"
            >
              <PictureAsPdfIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <pre
            style={{
              margin: 10,
              fontSize: 12,
              fontWeight: 1,
              whiteSpace: "-moz-pre-wrap",
              whiteSpace: "-pre-wrap",
              whiteSpace: "-o-pre-wrap",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {rowSelected.respuesta}
          </pre>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions> */}
      </Dialog>
    </Box>
  );
};
