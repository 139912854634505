import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { loginService } from "../services/AuthService";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";


const defaultTheme = createTheme();

export default function Demo() {
	const { login } = React.useContext(AuthContext);
	const navigate = useNavigate();

	const onLogin = async (event) => {
		try {
			const response = await loginService("demoaiep", "demoaiep2024");
			// console.log(response);
			login(response, "Colaborador");
			// window.location.href = "http://localhost:3001/"
			navigate("/app", {
				replace: true,
			});
		} catch (error) {
			// console.error('error', error);
		}
	};

    React.useEffect(() => {
        onLogin();
    }, [])
    

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
			</Container>
		</ThemeProvider>
	);
}
