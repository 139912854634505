import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { AppRouter } from "./routes/AppRouter";
import { AuthProvider } from "./context/AuthProvider";

const mdTheme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
    allVariants: {
      fontWeight: 600,
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />

      <AuthProvider>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}
