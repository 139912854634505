import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";

const pages = ["Inicio"];
const settings = ["Salir"];

export const Barra = ({ handleDrawerToggle }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { user, logout } = React.useContext(AuthContext);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logout();

        navigate("/login");
      }
      return error;
    }
  );

  const navigate = useNavigate();

  const handleLogOut = () => {
    logout();

    navigate("/");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <AppBar position="fixed" style={{ background: "#022746", zIndex: 1201 }}>
      <Toolbar disableGutters>
        <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1, p: 1 }}>
          <img src="/logo2.svg" width={100} alt="logo aiep" />
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            <MenuItem onClick={handleCloseNavMenu}>
              <Typography
                textAlign="center"
                onClick={() => {
                  navigate("/app");
                }}
              >
                Inicio
              </Typography>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleCloseNavMenu();
                handleDrawerToggle();
              }}
            >
              <Typography textAlign="center">Abrir Opciones</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseNavMenu();
                navigate("/record");
              }}
            >
              <Typography textAlign="center">Historial</Typography>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleCloseNavMenu();
                navigate("/ayuda");
              }}
            >
              <Typography textAlign="center">Ayuda</Typography>
            </MenuItem>
          </Menu>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
          <img src="/logo2.svg" width={100} alt="logo aiep" />
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Button
            key={"Inicio"}
            onClick={() => {
              handleCloseNavMenu();
              navigate("/app");
            }}
            sx={{ my: 2, color: "white", display: "block" }}
          >
            {"Inicio"}
          </Button>
          <Button
            key={"Historial"}
            onClick={() => {
              handleCloseNavMenu();
              navigate("/record");
            }}
            sx={{ my: 2, color: "white", display: "block" }}
          >
            Historial
          </Button>
          <Button
            key={"Ayuda"}
            onClick={() => {
              handleCloseNavMenu();
              navigate("/ayuda");
            }}
            sx={{ my: 2, color: "white", display: "block" }}
          >
            Ayuda
          </Button>
        </Box>

        <Box sx={{ flexGrow: 0, p: 1 }}>
          <Tooltip title="Abrir opciones">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt={user && user.user}
                src="/static/images/avatar/2.jpg"
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center" onClick={handleLogOut}>
                  {setting}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
