import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import { PrivateRoute } from "./PrivateRoute";
import Home from "../pages/Home";
import { Chat } from "../pages/Chat";
import Index from "../pages/Index";
import { PublicRoute } from "./PublicRoute";
import Help from "../pages/Help";
import { History } from "../pages/History";
import Demo from "../pages/Demo";

export const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route
          path="/login/*"
          element={
            <PublicRoute>
              <Routes>
                <Route path="/" element={<Login />} />
                
              </Routes>
            </PublicRoute>
          }
        />

        <Route path="/" element={<Index />} />
        <Route path="/ayuda" element={<Help />} />
        <Route path="/demo" element={<Demo />} />


        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Routes>
                <Route path="app" element={<Home />} />
                <Route path="chat" element={<Chat />} />
                <Route path="record" element={<History />} />
              </Routes>
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};
