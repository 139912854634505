import "./css_index/main.css";
import "./css_index/style.css";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Link, useNavigate } from "react-router-dom";

const pages = ["Inicio"];
const settings = ["Salir"];

function Index() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/login");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div>
      <AppBar position="fixed" style={{ background: "#022746", zIndex: 1201 }}>
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1, p: 1 }}>
            <img src="/logo2.svg" width={100} alt="logo aiep" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  navigate("/");
                }}
              >
                <Typography textAlign="center">Inicio</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  navigate("/ayuda");
                }}
              >
                <Typography textAlign="center">Help</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
            <img src="/logo2.svg" width={100} alt="logo aiep" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {/* {pages.map((page) => ( */}
            <Button
              // key={page}
              onClick={() => {
                handleCloseNavMenu();
                navigate("/");
              }}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Inicio
            </Button>
            <Button
              // key={page}
              onClick={() => {
                handleCloseNavMenu();
                navigate("/ayuda");
              }}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Ayuda
            </Button>
            {/* ))} */}
          </Box>

          <Box sx={{ flexGrow: 0, p: 1 }}>
            <Button
              key={"login"}
              onClick={goToLogin}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Iniciar Sesión
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <div className="titlecontainer" style={{ marginTop: 120 }}>
        <div className="titlepage text-center">
          <h2>Asistente Instruccional</h2>
        </div>
      </div>

      <div className="wrapper">
        <div className="card-container" style={{ justifyContent: "center" }}>
          <a href="#" style={{ textDecoration: "none" }}>
            <div className="card-secundaria">
              <div className="card-body">
                <i>
                  <img src="img/we1.png" alt="#" />
                </i>
                <h3>¿Qué es el Asistente Instruccional?</h3>
                <p>
                  Es una iniciativa que aplica la inteligencia artificial
                  generativa(IAg) permitiendo mejorar la eficiencia, eficacia y
                  acelerar la creación instruccional de recursos de aprendizaje,
                  proporcionando apoyo a la docencia, con el fin de aportar a la
                  innovación y resultados académicos.
                </p>
              </div>
            </div>
          </a>

          <a href="#" style={{ textDecoration: "none" }}>
            <div className="card-secundaria">
              <div className="card-body">
                <i>
                  <img src="img/we2.png" alt="#" />
                </i>
                <h3>
                  Tecnología <br />
                  AI
                </h3>
                <p>
                  El proyecto Asistente Instruccional emplea la avanzada
                  tecnología de inteligencia artificial generativa basada en el
                  modelo GPT para integrar y mejorar los contenidos y recurso
                  educativos digitales.
                </p>
              </div>
            </div>
          </a>

          <a href="#" style={{ textDecoration: "none" }}>
            <div className="card-secundaria">
              <div className="card-body">
                <i>
                  <img src="img/we3.png" alt="#" />
                </i>
                <h3>
                  Equipo de <br />
                  Desarrollo
                </h3>
                <p>
                  Asistente Instruccional es un proyecto liderado por un equipo
                  integral de la Dirección Nacional de Desarrollo Académico de
                  AIEP, en el que desarrolladores, profesores e ingenieros se
                  unen para incorporar la inteligencia artificial con el
                  objetivo de optimizar los resultados académicos.
                </p>
              </div>
            </div>
          </a>

          <a href="#" style={{ textDecoration: "none" }}>
            <div className="card-secundaria">
              <div className="card-body">
                <i>
                  <img src="img/we1.png" alt="#" />
                </i>
                <h3>Participa</h3>
                <p>
                  ¡Docente, te invitamos a formar parte de Asistente
                  Instruccional, un proyecto pionero que incorpora IA a para
                  apoyar tus clases! Tu experiencia es crucial para mejorar la
                  calidad y eficiencia de nuestra enseñanza. Únete a nosotros y
                  sé parte del futuro de la educación digital. ¡Te esperamos!
                </p>
              </div>
            </div>
          </a>

          <a href="#" style={{ textDecoration: "none" }}>
            <div className="card-secundaria">
              <div className="card-body">
                <i>
                  <img src="img/we2.png" alt="#" />
                </i>
                <h3>
                  Investigación y <br />
                  resultados
                </h3>
                <p>
                  Investigar los resultados de Asistente Instruccional es vital
                  para entender y optimizar el impacto de la inteligencia
                  artificial en los resultados académicos, potenciando la
                  calidad y eficiencia de la educación en la era digital.
                </p>
              </div>
            </div>
          </a>

          <a href="#" style={{ textDecoration: "none" }}>
            <div className="card-secundaria">
              <div className="card-body">
                <i>
                  <img src="img/we3.png" alt="#" />
                </i>
                <h3>
                  Roadmap <br />
                  desarrollo e implementación
                </h3>
                <p>
                  Asistente Instruccional traza una ruta que incorpora
                  tecnología de inteligencia artificial basada en GPT, sigue con
                  la verificación de su eficacia, su implementación en el
                  entorno de aprendizaje y concluye con un análisis riguroso
                  para garantizar una mejora continua del proceso de enseñanza y
                  aprendizaje.
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div id="widget">
        <div className="informa">
          <div>
            <a className="logo_footer" href="index.html">
              <img className="text-center" src="img/logo2.svg" alt="#" />
            </a>
          </div>
        </div>
        <div className="informa">
          <ul>
            <li>CALL CENTER ADMISIÓN:</li>
            <li>
              <a href="tel:+566004501500" target="_blank">
                600 - 450 1500
              </a>
            </li>
            <li>HORARIO DE ATENCIÓN:</li>
            <li>LUN a VIE de 09:00 a 21:00 hrs. y SÁB de 09:00 a 14:00 hrs.</li>
          </ul>
        </div>

        <div className="informa">
          <ul>
            <li>SERVICIO AL ESTUDIANTE</li>
            <li>
              <a href="tel:+566005855050" target="_blank">
                600 - 585 5050
              </a>{" "}
            </li>
            <li>HORARIO DE ATENCIÓN:</li>
            <li>09:00 A 18:00 hrs.</li>
          </ul>
        </div>

        <div className="informa">
          <ul>
            <li>CONTÁCTENOS</li>
            <li>
              <a href="mailto:tecnologia.educativa@aiep.cl" target="_blank">
                tecnologia.educativa@aiep.cl
              </a>{" "}
            </li>
          </ul>
        </div>
      </div>

      <div id="footer">
        <div className="copyright footer-col-left">
          <p>
            © 2023.{" "}
            <a href="https://www.aiep.cl/">VICERRECTORÍA ACADÉMICA AIEP</a>
          </p>
        </div>
        <div className="footer-col-right">
          <ul className="social_icon text_align_center">
            <li>
              {" "}
              <a href="https://web.facebook.com/admision.aiep" target="_blank">
                <i className="fa fa-facebook-f"></i>
              </a>
            </li>
            <li>
              {" "}
              <a href="https://twitter.com/AIEP" target="_blank">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://cl.linkedin.com/school/instituto-profesional-aiep/"
                target="_blank"
              >
                <i className="fa fa-linkedin-square" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://www.instagram.com/admision_aiep/"
                target="_blank"
              >
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              {" "}
              <a href="https://www.youtube.com/@AIEPtv" target="_blank">
                <i className="fa fa-youtube-play" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Index;
