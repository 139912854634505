import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  AppBar,
  Breadcrumbs,
  Button,
  Divider,
  Fab,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/ContentCopy";
import SendIcon from "@mui/icons-material/Send";
import RighArrowIcon from "@mui/icons-material/ArrowRightAlt";
import EditIcon from "@mui/icons-material/Edit";
import Drawer from "@mui/material/Drawer";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";

import { Barra } from "../components/Barra";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const Chat = (props) => {
  const { window } = props;
  const params = useLocation();
  const { user } = React.useContext(AuthContext);
  const [ejecuciones, setEjecuciones] = React.useState([]);
  const [mensajesToSend, setMensajesToSend] = React.useState([]);
  const [mensajes, setMensajes] = React.useState([]);
  const [lastMensaje, setLastMensaje] = React.useState("");
  const [preguntaAbierta, setPreguntaAbierta] = React.useState("");

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
      sx={{ fontSize: 11 }}
    >
      Inicio
    </Link>,
    <Typography key="2" color="inherit" sx={{ fontSize: 11 }}>
      {params.state.asignaturaSelected.label}
    </Typography>,
    <Typography key="3" color="inherit" sx={{ fontSize: 11 }}>
      {params.state.unidadeSelected.label}
    </Typography>,
    <Typography key="4" color="text.primary" sx={{ fontSize: 11 }}>
      {params.state.aprendizajeSelected.label}
    </Typography>,
  ];

  const getPrompt = async (asignatura_id, unidad_id, aprendizaje_id) => {
    try {
      let data = JSON.stringify({
        asignatura_id: asignatura_id,
        unidad_id: unidad_id,
        aprendizaje_id: aprendizaje_id,
      });

      let config = {
        method: "post",
        url: "https://sabiduria.dtedevelop.cl/api/prompt",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.authorization.token,
        },
        data: data,
      };

      const response = await axios.request(config);

      return response.data.prompt;
    } catch (error) {
      console.log(error);
    }
  };

  const getEjecuciones = async (a) => {
    try {
      let config = {
        method: "get",
        url: "https://sabiduria.dtedevelop.cl/api/ejecucion",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.authorization.token,
        },
      };

      const response = await axios.request(config);
      setEjecuciones(response.data.ejecuciones);
      // console.log(response.data.ejecuciones);
    } catch (error) {
      console.log(error);
    }
  };

  const sendBitacora = async (pregunta, respuesta, ejecucion_id) => {
    let data = new FormData();
    data.append("usuario", user.user);
    data.append("starttime", new Date());
    data.append("endtime", new Date());
    data.append("pregunta", pregunta);
    data.append("respuesta", respuesta);
    data.append("aprendizaje_id", params.state.aprendizajeSelected.id);
    data.append("ejecucion_id", ejecucion_id);

    let config = {
      method: "post",
      url: "https://sabiduria.dtedevelop.cl/api/bitacora",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.authorization.token,
      },
      data: data,
    };

    const response = await axios.request(config);
  };

  const getFirstMessage = async (prompt) => {
    try {
      let data = new FormData();
      data.append("prompt", prompt);

      let config = {
        method: "post",
        url: "https://sabiduria.dtedevelop.cl/api/firstmessage",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.authorization.token,
        },
        data: data,
      };

      const response = await axios.request(config);
      // console.log(response);
      setMensajesToSend(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    const main = async () => {
      try {
        const prompt = await getPrompt(
          params.state.asignaturaSelected.id,
          params.state.unidadeSelected.id,
          params.state.aprendizajeSelected.id
        );
        await getFirstMessage(prompt);
        await getEjecuciones();
      } catch (err) {
        console.log(err);
        // errorPrompt('Failed trying to retrieve custom parameters! ' + err)
      }
    };
    main();
  }, []);

  const getMessage = async (ejecucion_id) => {
    setLastMensaje("");

    try {
      let newMensajesToSend = [...mensajesToSend];
      newMensajesToSend.push({ role: "user", content: preguntaAbierta });
      // console.log(newMensajesToSend);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + user.authorization.token);

      var raw = JSON.stringify({
        mensajes: ejecucion_id == 0 ? newMensajesToSend : mensajesToSend,
        ejecucion_id: ejecucion_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      const response = await fetch(
        "https://sabiduria.dtedevelop.cl/api/message",
        requestOptions
      );

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      // setMensajes((prevArray) => [
      //   ...prevArray,
      //   { role: "assistance", content: "" },
      // ]);
      let contenido = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          // setLoading(false);
          // setMensajes((prevArray) => [
          //   ...prevArray,
          //   { role: "assistance", content: contenido },
          // ]);
          sendBitacora(
            ejecucion_id == 0
              ? JSON.stringify(newMensajesToSend)
              : JSON.stringify(mensajesToSend),
            contenido,
            ejecucion_id
          );
          // setLastMensaje("");
          break;
        }

        const decodedChunk = decoder.decode(value, { stream: true });
        contenido += decodedChunk;
        // resultRef.current = decodedChunk;
        // console.log(contenido)
        setLastMensaje(contenido);
        // let newArr = [...mensajes]; // copying the old datas array
        // // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        // newArr[newArr.length - 1].content = decodedChunk; // replace e.target.value with whatever you want to change it to

        // setMensajes(newArr);
      }
    } catch (error) {
      console.log("error");
    }
  };

  const editPrompt = (prompt) => {
    setPreguntaAbierta(prompt);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <Barra handleDrawerToggle={handleDrawerToggle} isOpen={mobileOpen} />

      <Box sx={{ display: "flex" }}>
        <Box
          component="nav"
          sx={{ width: { sm: 400 }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: 250,
              },
            }}
          >
            <div style={{ backgroundColor: "#EBF2F9" }}>
              <Toolbar />
              <Divider />
              <Box sx={{ flexWrap: "wrap" }} key={`ejecucion-${0}`}>
                <Card sx={{ margin: 1 }}>
                  <CardContent sx={{ minHeight: 70 }}>
                    {/* <TextField
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#EBF2F9",
                          },
                        },
                        "& label.Mui-focused": {
                          color: "#024588",
                        },
                      }}
                      id="outlined-basic-email"
                      label="Pregunta abierta"
                      value={preguntaAbierta}
                      onChange={(e) => {
                        setPreguntaAbierta(e.target.value);
                      }}
                      fullWidth
                      // multiline
                      rows={4}
                    /> */}
                    <textarea
                      name="textarea"
                      rows="10"
                      value={preguntaAbierta}
                      placeholder="Pregunta abierta"
                      onChange={(e) => {
                        setPreguntaAbierta(e.target.value);
                      }}
                    ></textarea>
                  </CardContent>
                  <CardActions disableSpacing>
                    <Button
                      size="small"
                      fullWidth
                      disabled={preguntaAbierta.length === 0}
                      endIcon={<RighArrowIcon />}
                      variant="outlined"
                      sx={{ backgroundColor: "#D7E5F4" }}
                      onClick={() => {
                        getMessage(0);
                      }}
                    >
                      Generar
                    </Button>
                  </CardActions>
                </Card>
              </Box>
              {ejecuciones.map((ejecucion, index) => (
                <Box sx={{ flexWrap: "wrap" }} key={`ejecucion-${index}`}>
                  <Card sx={{ margin: 1 }}>
                    <CardHeader
                      sx={{
                        position: "absolute",
                        marginTop: "-15px",
                        right: 0,
                      }}
                      action={
                        <IconButton
                          aria-label="settings"
                          onClick={async () => {
                            editPrompt(ejecucion.prompt);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                    ></CardHeader>
                    <CardContent sx={{ minHeight: 70 }}>
                      <Typography variant="body2" color="#024588">
                        {ejecucion.name}
                      </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                      <Button
                        size="small"
                        fullWidth
                        endIcon={<RighArrowIcon />}
                        variant="outlined"
                        sx={{ backgroundColor: "#D7E5F4" }}
                        onClick={async () => {
                          getMessage(ejecucion.id);
                        }}
                      >
                        Generar
                      </Button>
                    </CardActions>
                  </Card>
                </Box>
              ))}
            </div>
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: 400,
              },
            }}
            open
          >
            <div style={{ backgroundColor: "#EBF2F9" }}>
              <Toolbar />
              <Divider />
              <Box sx={{ flexWrap: "wrap" }} key={`ejecucion-${0}`}>
                <Card sx={{ margin: 1 }}>
                  <CardContent sx={{ minHeight: 70 }}>
                    {/* <TextField
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#EBF2F9",
                          },
                        },
                        "& label.Mui-focused": {
                          color: "#024588",
                        },
                      }}
                      id="outlined-basic-email"
                      label="Pregunta abierta"
                      value={preguntaAbierta}
                      onChange={(e) => {
                        setPreguntaAbierta(e.target.value);
                      }}
                      fullWidth
                      multiline
                      rows={4}
                    /> */}
                    <textarea
                      name="textarea"
                      rows="10"
                      cols="35"
                      placeholder="Pregunta abierta"
                      value={preguntaAbierta}
                      onChange={(e) => {
                        setPreguntaAbierta(e.target.value);
                      }}
                    ></textarea>
                  </CardContent>
                  <CardActions disableSpacing>
                    <Button
                      size="small"
                      fullWidth
                      disabled={preguntaAbierta.length === 0}
                      endIcon={<RighArrowIcon />}
                      variant="outlined"
                      sx={{ backgroundColor: "#D7E5F4" }}
                      onClick={() => {
                        getMessage(0);
                      }}
                    >
                      Generar
                    </Button>
                  </CardActions>
                </Card>
              </Box>
              {ejecuciones.map((ejecucion, index) => (
                <Box sx={{ flexWrap: "wrap" }} key={`ejecucion-${index}`}>
                  <Card sx={{ margin: 1 }}>
                    <CardHeader
                      sx={{
                        position: "absolute",
                        marginTop: "-15px",
                        right: 0,
                      }}
                      action={
                        <IconButton
                          aria-label="settings"
                          onClick={async () => {
                            editPrompt(ejecucion.prompt);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                    ></CardHeader>
                    <CardContent sx={{ minHeight: 70 }}>
                      <Typography variant="body2" color="#024588">
                        {ejecucion.name}
                      </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                      <Button
                        size="small"
                        fullWidth
                        endIcon={<RighArrowIcon />}
                        variant="outlined"
                        sx={{ backgroundColor: "#D7E5F4" }}
                        onClick={async () => {
                          getMessage(ejecucion.id);
                        }}
                      >
                        Generar
                      </Button>
                    </CardActions>
                  </Card>
                </Box>
              ))}
            </div>
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{ p: 2, width: { sm: `calc(100% - ${240}px)` } }}
        >
          <Toolbar />
          <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ pb: 2 }}>
            {breadcrumbs}
          </Breadcrumbs>

          <Box
            sx={{
              pr: 2,
              height: "80vh",
              overflowY: "auto",
              borderColor: "#D7E5F4",
              borderStyle: "solid",
              borderRadius: 2,
              overflowX: "hidden",
            }}
          >
            {/* {mensajes &&
                mensajes.map((mensaje, index) => (
                  <ListItem
                    disableGutters
                    key={`mensaje-${index}`}
                    sx={{
                      backgroundColor: "#EBF2F9",
                      margin: 1,
                      borderRadius: 2,
                      width: "fit-content",
                    }}
                  >
                    <ListItemIcon
                      sx={{ position: "absolute", right: -20, top: 5, cursor: 'pointer' }}
                      onClick={() => {
                        navigator.clipboard.writeText(mensaje.content);
                      }}
                    >
                      <ShareIcon />
                    </ListItemIcon>
                    <Grid container>
                      <Grid item xs={12}>
                        <ListItemText align="left">
                          <pre
                            style={{
                              margin: 10,
                              fontSize: 12,
                              fontWeight: 1,
                              whiteSpace: "-moz-pre-wrap",
                              whiteSpace: "-pre-wrap",
                              whiteSpace: "-o-pre-wrap",
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}
                          >
                            {mensaje.content}
                          </pre>
                        </ListItemText>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))} */}
            {/* {lastMensaje.length > 0 && ( */}
            <div>
              <IconButton
                sx={{
                  position: "fixed",
                  right: 20,
                  //     top: 5,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(lastMensaje);
                }}
              >
                <ShareIcon />
              </IconButton>
              <pre
                style={{
                  margin: 10,
                  fontSize: 12,
                  fontWeight: 1,
                  whiteSpace: "-moz-pre-wrap",
                  whiteSpace: "-pre-wrap",
                  whiteSpace: "-o-pre-wrap",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
              >
                {lastMensaje}
              </pre>
            </div>

            {/* )} */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
