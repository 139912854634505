import axios from "axios";

export const loginService = async (username, password) => {
  let data = JSON.stringify({
    username: username,
    password: password,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://sabiduria.dtedevelop.cl/api/login",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response = await axios.request(config);


  return response.data;
};

export const loginServiceAcademico = async (username, password) => {
  let data = JSON.stringify({
    username: username,
    password: password,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://sabiduria.dtedevelop.cl/api/loginb",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response = await axios.request(config);


  return response.data;
};

export const isAuthenticated = () => {
  const user = localStorage.getItem("user");
  // console.log(user);
  if (!user) {
    return {};
  }
  return JSON.parse(user);
};


export const getPayload = (jwt) => {
  // A JWT has 3 parts separated by '.'
  // The middle part is a base64 encoded JSON
  // decode the base64 
  return atob(jwt.split(".")[1])
}
