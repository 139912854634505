import { useReducer } from "react";
import { AuthContext } from "./AuthContext";
import { authReducer } from "./authReducer";
import { types } from "../types/types";

// const initialState = {
//   logged: false,
// };

const init = () => {
  const userLs = JSON.parse(localStorage.getItem("user"));
    // console.log(userLs.user);
  return {
    logged: !!userLs,
    user: userLs
  }
};

export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(authReducer, {}, init);

  const login = async (element = {}, tipo = '') => {
    // console.log(element, tipo);
    const userLs = {
      user: element.user,
      rut: element.rut,
      tipo: tipo,
      authorization: {
        token: element.authorization.token,
      },
    };

    const action = {
      type: types.login,
      payload: userLs
    };

    localStorage.setItem("user", JSON.stringify(userLs));

    dispatch(action);
  };

  const logout = () => {
    localStorage.removeItem('user');
    const action = {type: types.logout};
    dispatch(action);
  }


  return (
    <AuthContext.Provider
      value={{
        ...authState,
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
