import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Copyright } from "../components/Copyright";
import { loginService, loginServiceAcademico } from "../services/AuthService";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { grey } from "@mui/material/colors";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
  const { login } = React.useContext(AuthContext);
  const [error, setError] = React.useState(false);
  const [isloading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [tipo, setTipo] = React.useState("Colaborador");
  const [open, setOpen] = React.useState(false);
  const [valueCbx, setValueCbx] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setTipo(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true);

    if (tipo === "Colaborador") {
      try {
        const response = await loginService(
          data.get("username"),
          data.get("password")
        );
        // console.log(response);
        login(response, tipo);
        setError(false);
        setLoading(false);
        // window.location.href = "http://localhost:3001/"
        navigate("/app", {
          replace: true,
        });
      } catch (error) {
        setLoading(false);
        setError(true);
        // console.error('error', error);
      }
    } else {
      try {
        const response = await loginServiceAcademico(
          data.get("username"),
          data.get("password")
        );
        // console.log(response);
        login(response, tipo);
        setError(false);
        setLoading(false);
        // window.location.href = "http://localhost:3001/"
        navigate("/app", {
          replace: true,
        });
      } catch (error) {
        setLoading(false);
        setError(true);
        // console.error('error', error);
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src="/logo.svg" width={300} alt="logo aiep" />
          <Typography component="h1" variant="h5" sx={{ mt: 6 }}>
            Iniciar Sesión
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 2 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Nombre de usuario"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="demo-simple-select-label">
                Seleccione una opción
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tipo}
                label="Seleccione una opción"
                onChange={handleChange}
              >
                <MenuItem value={"Colaborador"}>Colaborador</MenuItem>
                <MenuItem value={"Academico"}>Docente</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={valueCbx}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    setValueCbx(!valueCbx);
                  }}
                />
              }
              label={
                <p>
                  Acepto los{" "}
                  <Link onClick={handleClickOpen}>términos y condiciones</Link>
                </p>
              }
            />
            {error && (
              <Typography color={"grey"}>
                !Credenciales Incorrectas¡ reintente...
              </Typography>
            )}
            <Button
              type="submit"
              disabled={isloading || !valueCbx}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, background: "#022746" }}
            >
              {isloading ? <CircularProgress /> : "Ingresar"}
            </Button>
          </Box>
        </Box>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Términos y Condiciones"}
          </DialogTitle>
          <DialogContent>
            <p>
              El Instituto Profesional AIEP ha puesto a disposición de su
              comunidad, herramientas y aplicaciones tecnológicas con uso de
              Inteligencia Artificial (IA), para apoyar a los usuarios
              (estudiantes, docentes y encargados académicos) en el avance de
              estudios y en la entrega de un servicio más moderno y útil, y
              respecto de la cual es esencial tener presente las siguientes
              condiciones de uso:
            </p>
            <br></br>
            <p>
              <b>1.</b> La IA aplicada al área académica corresponde a una
              herramienta que funciona computacionalmente en base a algoritmos a
              fin de entregar respuestas o información tomando en consideración
              el programa del módulo, la bibliografía asociada y la base de
              conocimiento de la IA.
            </p>
            <br></br>
            <p>
              <b>2.</b> Esta herramienta es un apoyo para los estudiantes,
              generando respuestas lo más precisas posibles de acuerdo a la
              información disponible, pero en caso alguno debe tomarse como
              verdad absoluta entendiendo que existe un margen de error. A este
              respecto el docente es el único facultado para determinar si una
              respuesta es correcta o no, de manera tal que, en caso de
              divergencia entre ambas, primará la opinión del docente.
            </p>
            <br></br>
            <p>
              <b>3.</b> 3. De acuerdo a lo anterior, el uso de las herramientas
              y aplicaciones de IA se realiza bajo el propio riesgo del usuario,
              no siendo responsable AIEP de los errores, omisiones,
              interrupciones, eliminaciones, defectos, retrasos en la operación
              o transmisión, fallos de la línea de comunicación, robo o
              destrucción o acceso no autorizado a, o alteración de, las
              comunicaciones del usuario.
            </p>
            <br></br>
            <p>
              <b>4.</b> Los usuarios deben utilizar las herramientas,
              aplicaciones y productos que genere la IA de manera responsable y
              ética. Cualquier uso indebido puede resultar en la suspensión o
              terminación del acceso a estas herramientas y aplicaciones.
            </p>
            <br></br>
            <p>
              <b>5.</b> Los usuarios deben respetar todos los derechos de
              propiedad intelectual y de autor, incluyendo el uso de software de
              IA, datos y obras de terceros.
            </p>
            <br></br>
            <p>
              <b>6.</b> No se tolerará ninguna forma de conducta académica
              deshonesta relacionada con el uso de la IA, como el plagio, la
              falsificación de datos o la violación de los derechos de autor.
            </p>
            <br></br>
            <p>
              <b>7.</b> AIEP se compromete a proteger los datos personales de
              sus estudiantes, docentes y otros miembros de la comunidad AIEP,
              tanto en la recopilación, almacenamiento, uso y divulgación de
              datos personales, en los términos contemplados en la ley.
            </p>
            <br></br>
            <p>
              <b>8.</b> Estas Condiciones de Uso serán revisadas y actualizadas
              cuando los avances en la tecnología de IA y las mejores prácticas
              en su uso en la educación técnico profesional lo exijan.
            </p>
            <br></br>
            <p>
              Al utilizar las herramientas y aplicaciones de IA proporcionadas
              por AIEP, el usuario acepta estas Condiciones de Uso. En caso
              contrario, deberá abstenerse de su uso.
            </p>
            <br></br>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose}>Cerrar</Button> */}
            <Button onClick={handleClose} autoFocus>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}
